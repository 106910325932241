var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _c("div", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "mb-5" }, [
          _c(
            "h1",
            { staticClass: "theme-text" },
            [
              _vm.$route.query.q == null
                ? [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.selectedCategory != null
                            ? _vm.selectedCategory.name
                            : "All Products"
                        ) +
                        " "
                    )
                  ]
                : [
                    _vm._v(
                      " Results for: " + _vm._s(_vm.$route.query.q) + " ("
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link btn-sm",
                        staticStyle: { padding: "0" },
                        on: { click: _vm.clearQuery }
                      },
                      [_vm._v(" Clear")]
                    ),
                    _vm._v(") ")
                  ]
            ],
            2
          )
        ]),
        _c("div", { staticClass: "filter1 mb-4" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "filter-wrapper" },
                [
                  _c("span", [_vm._v("Categories:")]),
                  _c("CategoriesDropdown", {
                    ref: "categoryDropdown",
                    attrs: { allowBlank: true },
                    on: { changeCategory: _vm.categoryChanged }
                  })
                ],
                1
              )
            ]),
            _vm.selectedCategory != null
              ? _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "filter-wrapper" },
                    [
                      _c("span", { staticClass: "text-nowrap" }, [
                        _vm._v("Sub-Cats:")
                      ]),
                      _c("SubCategoriesDropdown", {
                        key: _vm.selectedCategory.id,
                        ref: "subCategoryDropdown",
                        attrs: { parentCategory: _vm.selectedCategory },
                        on: { changeSubCategory: _vm.subCategoryChanged }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "filter-wrapper" },
                [
                  _c("span", { staticClass: "text-nowrap" }, [
                    _vm._v("Brands:")
                  ]),
                  _c("BrandsSelector", {
                    ref: "brandsSelector",
                    on: { changeBrands: _vm.brandsChanged }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "col d-none" }, [
              _c("div", { staticClass: "filter-wrapper" }, [
                _c("span", { staticClass: "text-nowrap" }, [
                  _vm._v("Sort By:")
                ]),
                _c("div", { staticClass: "selectbox1" }, [
                  _c("select", [
                    _c("option", [_vm._v("Please select")]),
                    _c("option", [_vm._v("Price: High to Low")]),
                    _c("option", [_vm._v("Price: Low to High")]),
                    _c("option", [_vm._v("Date: Newest")]),
                    _c("option", [_vm._v("Date: Oldest")])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-3 mb-4 mb-lg-0" },
            [
              _vm.selectedSubCategory == null ? _c("DefaultFilters") : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Dishwashers"
                ? _c("DishwasherFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Cordless Vacuum Cleaners"
                ? _c("CordlessVacuumCleanerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name ==
                "Built-in fully automatic coffee machines"
                ? _c("CoffeeMachineFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Freestanding Fridges"
                ? _c("FreestandingFridgeFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Integrated Fridges"
                ? _c("IntegratedFridgeFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "American Style Fridge Freezers"
                ? _c("AmericanStyleFridgeFreezerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Freestanding Freezers"
                ? _c("FreestandingFreezerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Integrated Freezers"
                ? _c("IntegratedFreezerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Washing Machines"
                ? _c("WashingMachineFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Heat Pump Dryers"
                ? _c("HeatPumpDryerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Washer Dryers"
                ? _c("WasherDryerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Condenser Tumble Dryers"
                ? _c("CondenserTumbleDryerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Ovens"
                ? _c("OvenFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Hobs"
                ? _c("HobsFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Freestanding Microwaves"
                ? _c("FreestandingMicrowaveFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Built-in Ovens with Microwave"
                ? _c("BuiltinOvenwithMicrowaveFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Double Ovens"
                ? _c("DoubleOvenFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Warming Drawers"
                ? _c("WarmingDrawerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Extractors"
                ? _c("ExtractorFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Integrated Fridge Freezers"
                ? _c("IntegratedFridgeFreezerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e(),
              _vm.selectedSubCategory != null &&
              _vm.selectedSubCategory.name == "Fridge Freezers"
                ? _c("FridgeFreezerFilters", {
                    on: { changeFilters: _vm.filtersChanged }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "col-lg-9 product-details" }, [
            _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center mt-5"
                  },
                  [
                    _c("span", {
                      staticClass: "spinner-grow spinner-grow-sm mx-3",
                      staticStyle: { width: "2rem", height: "2rem" },
                      attrs: { role: "status", "aria-hidden": "true" }
                    }),
                    _c("span", { staticClass: "text-md" }, [
                      _vm._v("Loading....")
                    ])
                  ]
                )
              : _c(
                  "div",
                  [
                    _c("div", { staticClass: "row page-view" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("span", { staticClass: "showing" }, [
                          _vm._v(
                            "Showing " +
                              _vm._s(_vm.products.length) +
                              " of " +
                              _vm._s(_vm.rows) +
                              " products"
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", { staticClass: "page-view-btns" }, [
                          _c(
                            "button",
                            {
                              class: { active: _vm.layout == "grid" },
                              on: {
                                click: function($event) {
                                  _vm.layout = "grid"
                                }
                              }
                            },
                            [_c("span", { staticClass: "fa fa-th" })]
                          ),
                          _c(
                            "button",
                            {
                              class: { active: _vm.layout == "list" },
                              on: {
                                click: function($event) {
                                  _vm.layout = "list"
                                }
                              }
                            },
                            [_c("span", { staticClass: "fa fa-list-ul" })]
                          )
                        ])
                      ])
                    ]),
                    _vm.layout === "list"
                      ? _vm._l(_vm.products, function(product) {
                          return _c(
                            "div",
                            {
                              key: product.id,
                              staticClass: "product",
                              attrs: { product: product }
                            },
                            [
                              _c("div", { staticClass: "image" }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                      product.featured_image +
                                      ".jpg",
                                    alt: product.code,
                                    width: "126"
                                  }
                                }),
                                product.is_featured
                                  ? _c(
                                      "span",
                                      { staticClass: "featured-tag rotated" },
                                      [_vm._v("Buy Direct Model")]
                                    )
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "details" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-xl-9 col-lg-8" },
                                    [
                                      _c("h4", [
                                        _vm._v(
                                          " " +
                                            _vm._s(product.brand.name) +
                                            " " +
                                            _vm._s(product.series) +
                                            " " +
                                            _vm._s(product.code) +
                                            " " +
                                            _vm._s(product.name) +
                                            " "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-xl-3 col-lg-4 col2" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "wrapper" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row align-items-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-lg-12 col-md-6"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "checkbox" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.selectedProductIds,
                                                            expression:
                                                              "selectedProductIds"
                                                          }
                                                        ],
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "list-product-" +
                                                            product.id
                                                        },
                                                        domProps: {
                                                          value: product.id,
                                                          checked: Array.isArray(
                                                            _vm.selectedProductIds
                                                          )
                                                            ? _vm._i(
                                                                _vm.selectedProductIds,
                                                                product.id
                                                              ) > -1
                                                            : _vm.selectedProductIds
                                                        },
                                                        on: {
                                                          change: [
                                                            function($event) {
                                                              var $$a =
                                                                  _vm.selectedProductIds,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    product.id,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    (_vm.selectedProductIds = $$a.concat(
                                                                      [$$v]
                                                                    ))
                                                                } else {
                                                                  $$i > -1 &&
                                                                    (_vm.selectedProductIds = $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                                }
                                                              } else {
                                                                _vm.selectedProductIds = $$c
                                                              }
                                                            },
                                                            function($event) {
                                                              return _vm.productComparisionChanged(
                                                                product,
                                                                $event
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for:
                                                              "list-product-" +
                                                              product.id
                                                          }
                                                        },
                                                        [_vm._v("Compare")]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "theme-bg btn btn-primary",
                                              attrs: {
                                                to: /product/ + product.id,
                                                id:
                                                  product.code +
                                                  "-product-view-button"
                                              }
                                            },
                                            [_vm._v("View")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]
                          )
                        })
                      : _vm._e(),
                    _vm.layout === "grid"
                      ? [
                          _c(
                            "div",
                            { staticClass: "row grid-view-wrapper" },
                            _vm._l(_vm.products, function(product) {
                              return _c(
                                "div",
                                {
                                  key: product.id,
                                  staticClass: "col-xl-4 col-md-6"
                                },
                                [
                                  _c("div", { staticClass: "product" }, [
                                    _c(
                                      "div",
                                      { staticClass: "row no-gutters" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-12 product-image"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "image" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src:
                                                      "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                                      product.featured_image +
                                                      ".jpg",
                                                    alt: product.code,
                                                    height: "91"
                                                  }
                                                }),
                                                product.is_featured
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "featured-tag rotated"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Buy Direct Model"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 description" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "details" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-12" },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "theme-text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  product.brand
                                                                    .name
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  product.series
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  product.code
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  product.name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 mt-auto"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "wrapper"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row align-items-center"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-6"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "checkbox"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives: [
                                                                              {
                                                                                name:
                                                                                  "model",
                                                                                rawName:
                                                                                  "v-model",
                                                                                value:
                                                                                  _vm.selectedProductIds,
                                                                                expression:
                                                                                  "selectedProductIds"
                                                                              }
                                                                            ],
                                                                            attrs: {
                                                                              type:
                                                                                "checkbox",
                                                                              id:
                                                                                "grid-product-" +
                                                                                product.id
                                                                            },
                                                                            domProps: {
                                                                              value:
                                                                                product.id,
                                                                              checked: Array.isArray(
                                                                                _vm.selectedProductIds
                                                                              )
                                                                                ? _vm._i(
                                                                                    _vm.selectedProductIds,
                                                                                    product.id
                                                                                  ) >
                                                                                  -1
                                                                                : _vm.selectedProductIds
                                                                            },
                                                                            on: {
                                                                              change: [
                                                                                function(
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      _vm.selectedProductIds,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c = $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        product.id,
                                                                                      $$i = _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        (_vm.selectedProductIds = $$a.concat(
                                                                                          [
                                                                                            $$v
                                                                                          ]
                                                                                        ))
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        (_vm.selectedProductIds = $$a
                                                                                          .slice(
                                                                                            0,
                                                                                            $$i
                                                                                          )
                                                                                          .concat(
                                                                                            $$a.slice(
                                                                                              $$i +
                                                                                                1
                                                                                            )
                                                                                          ))
                                                                                    }
                                                                                  } else {
                                                                                    _vm.selectedProductIds = $$c
                                                                                  }
                                                                                },
                                                                                function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.productComparisionChanged(
                                                                                    product,
                                                                                    $event
                                                                                  )
                                                                                }
                                                                              ]
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs: {
                                                                              for:
                                                                                "grid-product-" +
                                                                                product.id
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Compare"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "router-link",
                                                              {
                                                                staticClass:
                                                                  "btn btn-primary",
                                                                attrs: {
                                                                  to:
                                                                    /product/ +
                                                                    product.id,
                                                                  id:
                                                                    product.code +
                                                                    "-product-view-button"
                                                                }
                                                              },
                                                              [_vm._v("View")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      : _vm._e(),
                    _c("b-pagination", {
                      attrs: {
                        "total-rows": _vm.rows,
                        "per-page": _vm.perPage
                      },
                      on: { change: _vm.pageChanged },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  2
                )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }